import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navbar, ScrollArea, createStyles, rem } from "@mantine/core";
import {
  /*IconList,
  IconChartCircles,
  IconVideo,
  IconSettings,
  IconBuildingMonument,
  IconBuildingArch,
  IconBuildingCommunity,
  IconChartTreemap,
  IconLogout2,
  IconBookmark,*/
  IconHome,
  IconArrowBack
} from "@tabler/icons-react";

import { LinksGroup } from "./NavbarLinksGroup";
import { FormattedMessage } from "react-intl";
import {
  setPreloaded /*, setShowRaceboard, setClickedMarker*/
} from "../redux/app/slice";
import { appSelector } from "../redux/app/selectors";

const nomFedLinks = [
  {
    key: "nom-2024",
    label: "Lista Nominal",
    year: "nom-2024",
    raceType: "pres"
  }
];

const fedLinks = [
  { key: "2024", label: "2024", year: "2024", raceType: "pres" },
  { key: "2021", label: "2021", year: "2021", raceType: "cong" },
  { key: "2018", label: "2018", year: "2018", raceType: "pres" },
  { key: "2015", label: "2015", year: "2015", raceType: "cong" },
  { key: "2012", label: "2012", year: "2012", raceType: "pres" },
  { key: "2009", label: "2009", year: "2009", raceType: "cong" },
  { key: "2006", label: "2006", year: "2006", raceType: "pres" }
];

const senLinks = [
  { key: "2024", label: "2024", year: "2024", raceType: "sen" }
];

const govLinks = [
  {
    key: "pre-2024",
    label: "Results",
    year: "pre-2024",
    raceType: "gov"
  },
  {
    key: "post-2024",
    label: "2024",
    year: "post-2024",
    raceType: "gov"
  },
  {
    key: "post-2023",
    label: "2023",
    year: "post-2023",
    raceType: "gov"
  },
  {
    key: "post-2018",
    label: "2018",
    year: "post-2018",
    raceType: "gov"
  },
  {
    key: "post-2012",
    label: "2012",
    year: "post-2012",
    raceType: "gov"
  },
  {
    key: "post-2006",
    label: "2006",
    year: "post-2006",
    raceType: "gov"
  },
  {
    key: "post-2000",
    label: "2000",
    year: "post-2000",
    raceType: "gov"
  },
  {
    key: "post-1994",
    label: "1994",
    year: "post-1994",
    raceType: "gov"
  },
  {
    key: "women",
    label: <FormattedMessage id={"Women"} />,
    year: "women",
    raceType: "gov"
  },
  {
    key: "jal",
    label: "Jalisco",
    year: "jal",
    raceType: "gov"
  },
  {
    key: "cdmxgov",
    label: "CDMX 2021",
    year: "cdmx",
    raceType: "gov"
  },
  {
    key: "cdmxgov2024",
    label: "CDMX 2024",
    year: "cdmx24",
    raceType: "gov"
  }
];

const mayorLinks = [
  {
    key: "cdmx",
    label: "Valle de México",
    year: "mayor-cdmx",
    raceType: "mayor"
  },
  {
    key: "mty",
    label: "Monterrey",
    year: "mayor-mty",
    raceType: "mayor"
  },
  {
    key: "gdl",
    label: "Guadalajara",
    year: "mayor-gdl",
    raceType: "mayor"
  }
];

const mayorYears = ["2024", "2021", "2018", "2015"];

const menuData = [
  {
    label: <FormattedMessage id={"RaceChart"} />,
    key: "RaceChart",
    toggle: true
  },
  {
    label: <FormattedMessage id={"NationalResults"} />,
    key: "NationalResult",
    toggle: true
  },
  {
    label: <FormattedMessage id={"Show2023Results"} />,
    key: "2023Result",
    toggle: true
  },
  {
    label: <FormattedMessage id={"Show2024Murders"} />,
    key: "2024Murders",
    toggle: true
  },
  process.env.REACT_APP_HIDE_FED !== "1"
    ? {
        label: <FormattedMessage id={"Federal"} />,
        key: "Federal",
        //icon: IconBuildingMonument,
        initiallyOpened: true,
        links: nomFedLinks.concat(fedLinks)
      }
    : undefined,
  process.env.REACT_APP_HIDE_SEN !== "1"
    ? {
        label: <FormattedMessage id={"Senate"} />,
        key: "Senate",
        //icon: IconBuildingMonument,
        initiallyOpened: true,
        links: senLinks
      }
    : undefined,
  process.env.REACT_APP_HIDE_GOV !== "1"
    ? {
        label: <FormattedMessage id={"Gubernatorial"} />,
        key: "Gubernatorial",
        //icon: IconBuildingArch,
        initiallyOpened: false,
        links: govLinks
      }
    : undefined,
  process.env.REACT_APP_HIDE_MAYOR !== "1"
    ? {
        label: <FormattedMessage id={"Mayoral"} />,
        key: "Mayoral",
        //icon: IconBuildingArch,
        initiallyOpened: false,
        links: mayorLinks
      }
    : undefined,
  process.env.REACT_APP_HIDE_BOOKMARK !== "1"
    ? {
        label: <FormattedMessage id={"AddBookmark"} />,
        key: "AddBookmark"
        //icon: IconBookmark,
      }
    : undefined,
  process.env.REACT_APP_HIDE_BOOKMARK !== "1"
    ? {
        label: <FormattedMessage id={"Bookmarks"} />,
        key: "Bookmarks"
        //icon: IconList,
      }
    : undefined,
  {
    label: <FormattedMessage id={"VoteCircles"} />,
    key: "VoteCircles",
    //icon: IconChartCircles,
    toggle: true
  },
  // {
  //   label: "Media",
  //   key: 'Media',
  //   //icon: IconVideo,
  //   toggle: true
  // },
  {
    label: <FormattedMessage id={"ForceMunicipals"} />,
    key: "ForceMunicipals",
    //icon: IconChartTreemap,
    toggle: true
  },
  {
    label: <FormattedMessage id={"ForceDistricts"} />,
    key: "ForceDistricts",
    //icon: IconChartTreemap,
    toggle: true
  },
  /*{
    label: <FormattedMessage id={'Settings'} />,
    key: 'Settings',
    //icon: IconSettings
  },*/
  {
    label: <FormattedMessage id={"Telestrator"} />,
    key: "Telestrator",
    toggle: true
  },
  {
    label: <FormattedMessage id={"Reverse"} />,
    key: "Reverse",
    toggle: true
  },
  {
    label: "",
    key: "Preload",
    button: true,
    offLabel: "No",
    onLabel: "Yes"
  },
  {
    label: <FormattedMessage id={"Logout"} />,
    key: "Logout"
    //icon: IconLogout2
  }
];

const useStyles = createStyles((theme) => ({
  navbar: {
    //backgroundColor:
    //theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    backgroundColor: "rgba(237,237,237,0.5)",
    backdropFilter: "blur(5px)",
    paddingBottom: 0,
    marginTop: "89px",
    overflowX: "hidden",
    borderRight: "none"
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -0.75)`,
    marginTop: "-1.2rem"
  },

  linksInner: {
    //paddingTop: theme.spacing.md,
    paddingTop: "15px",
    paddingBottom: theme.spacing.xl
  },

  footer: {
    display: "none",
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`
  }
}));

export default function NavbarNested({
  mapRef,
  markerRef,
  popupRef,
  canvasRef,
  goHome,
  goBack,
  removeCover,
  addFilter,
  removeFilter,
  selectFeature,
  unselectFeature,
  zoomAndReveal,
  hideLayer,
  showLayer,
  getParallelFeatures,
  currentLayer
}) {
  const { classes } = useStyles();
  //const year = useSelector((state) => state.year.value);
  const raceType = useSelector((state) => state.raceType.value);
  const year = useSelector((state) => state.year.value);
  const party = useSelector((state) => state.party.value);
  const margin = useSelector((state) => state.margin.value);
  const turnout = useSelector((state) => state.turnout.value);
  const forceDis = useSelector((state) => state.forceDis.value);

  const app = useSelector(appSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (app.preload && !app.preloaded) {
      let interval = 0;
      const currRaceType = "cong"; //raceType;
      const currYear = "2021"; // year;

      nomFedLinks.forEach(function (flink) {
        setTimeout(() => {
          if (document.getElementById(flink.raceType + "_" + flink.year))
            document.getElementById(flink.raceType + "_" + flink.year).click();
        }, interval);
        interval += 2000;
      });

      fedLinks.forEach(function (flink) {
        setTimeout(() => {
          if (document.getElementById(flink.raceType + "_" + flink.year))
            document.getElementById(flink.raceType + "_" + flink.year).click();
        }, interval);
        interval += 2000;
      });

      senLinks.forEach(function (flink) {
        setTimeout(() => {
          if (document.getElementById(flink.raceType + "_" + flink.year))
            document.getElementById(flink.raceType + "_" + flink.year).click();
        }, interval);
        interval += 2000;
      });

      govLinks.forEach(function (glink) {
        setTimeout(() => {
          if (document.getElementById(glink.raceType + "_" + glink.year))
            document.getElementById(glink.raceType + "_" + glink.year).click();
        }, interval);
        interval += 2000;
      });

      mayorLinks.forEach(function (mlink) {
        mayorYears.forEach(function (myear) {
          setTimeout(() => {
            if (!document.getElementById(mlink.key + "_" + myear)) {
              document.getElementById(mlink.key).click();
              setTimeout(() => {
                if (document.getElementById(mlink.key + "_" + myear))
                  document.getElementById(mlink.key + "_" + myear).click();
              }, 3000);
            } else {
              if (document.getElementById(mlink.key + "_" + myear))
                document.getElementById(mlink.key + "_" + myear).click();
            }
          }, interval);
          interval += 6000;
        });
      });

      //Toggle Force Municipals and loop again
      setTimeout(() => {
        if (
          document.getElementById(
            fedLinks[0]["raceType"] + "_" + fedLinks[0]["year"]
          )
        )
          document
            .getElementById(fedLinks[0]["raceType"] + "_" + fedLinks[0]["year"])
            .click();
      }, interval);
      interval += 3000;

      setTimeout(() => {
        if (document.getElementById("ForceMunicipals"))
          document.getElementById("ForceMunicipals").click();
      }, interval);
      interval += 3000;

      fedLinks.forEach(function (flink) {
        setTimeout(() => {
          if (document.getElementById(flink.raceType + "_" + flink.year))
            document.getElementById(flink.raceType + "_" + flink.year).click();
        }, interval);
        interval += 3000;
      });

      //Toggle Vote Circles and loop again
      setTimeout(() => {
        if (document.getElementById("VoteCircles"))
          document.getElementById("VoteCircles").click();
      }, interval);
      interval += 3000;

      fedLinks.forEach(function (flink) {
        setTimeout(() => {
          if (document.getElementById(flink.raceType + "_" + flink.year))
            document.getElementById(flink.raceType + "_" + flink.year).click();
        }, interval);
        interval += 3000;
      });

      //Toggle back Force Municipals and Vote Circles
      setTimeout(() => {
        if (document.getElementById("ForceMunicipals"))
          document.getElementById("ForceMunicipals").click();
      }, interval);
      interval += 2000;

      setTimeout(() => {
        if (document.getElementById("VoteCircles"))
          document.getElementById("VoteCircles").click();
      }, interval);
      interval += 2000;

      //Toggle Force Districts and loop again
      setTimeout(() => {
        if (document.getElementById("cong_2021"))
          document.getElementById("cong_2021").click();
      }, interval);
      interval += 3000;

      setTimeout(() => {
        if (document.getElementById("ForceDistricts"))
          document.getElementById("ForceDistricts").click();
      }, interval);
      interval += 3000;

      fedLinks
        .filter((flink) => flink.raceType === "cong")
        .forEach(function (flink) {
          setTimeout(() => {
            if (document.getElementById(flink.raceType + "_" + flink.year))
              document
                .getElementById(flink.raceType + "_" + flink.year)
                .click();
          }, interval);
          interval += 3000;
        });

      //Toggle back Force Districts
      setTimeout(() => {
        if (document.getElementById("ForceDistricts"))
          document.getElementById("ForceDistricts").click();
      }, interval);
      interval += 2000;

      //go back to original map
      setTimeout(() => {
        if (document.getElementById(currRaceType + "_" + currYear))
          document.getElementById(currRaceType + "_" + currYear).click();
      }, interval);
      interval += 2000;

      setTimeout(() => {
        dispatch(setPreloaded(true));
      }, interval);
    }
  }, [app.preload, app.preloaded, dispatch]);
  
  let mData = menuData;
  if (app.project) {
    const menuDataProject = [
      {
        label: <FormattedMessage id={"Year"} />,
        key: "Year",
        //icon: IconBuildingMonument,
        initiallyOpened: true,
        links: app.projectYears
      },
      {
        label: <FormattedMessage id={"ForceMunicipals"} />,
        key: "ForceMunicipals",
        //icon: IconChartTreemap,
        toggle: true
      },
      {
        label: <FormattedMessage id={"ForceDistricts"} />,
        key: "ForceDistricts",
        //icon: IconChartTreemap,
        toggle: true
      },
      {
        label: <FormattedMessage id={"Telestrator"} />,
        key: "Telestrator",
        toggle: true
      },
      {
        label: <FormattedMessage id={"Reverse"} />,
        key: "Reverse",
        toggle: true
      },
      {
        label: <FormattedMessage id={"Logout"} />,
        key: "Logout"
        //icon: IconLogout2
      }
    ];
    mData = menuDataProject;
  }

  const links = mData.map((item) => {
    if (!item) return undefined;
    if (item.key === "ForceDistricts" && raceType !== "cong") return undefined;
    if (
      item.key === "ForceMunicipals" &&
      (raceType === "gov" || raceType === "sen" || raceType === "mayor")
    )
      return undefined;

    if (
      item.key === "VoteCircles" &&
      (raceType === "gov" ||
        raceType === "sen" ||
        raceType === "mayor" ||
        party ||
        margin ||
        turnout ||
        forceDis)
    )
      return undefined;

    if (
      item.key === "RaceChart" &&
      raceType === "gov" &&
      year !== "jal" &&
      year !== "cdmx" &&
      year !== "cdmx24"
    ) {
      return undefined;
    }

    if (item.key === "2023Result" && year !== "pre-2024") {
      return undefined;
    }

    if (item.key === "2024Murders" && year !== "2024") {
      return undefined;
    }

    if (
      (item.key === "NationalResult" || item.key === "RaceChart") &&
      raceType === "mayor"
    ) {
      return undefined;
    }

    if (item.key === "RaceChart" && (year === "nom-2024" || year === "cdmx24")) {
      return undefined;
    }

    if (item.key === "NationalResult") {
      if (year === "nom-2024") {
        item.label = <FormattedMessage id={"RegVotersTitle"} />;
      } else {
        item.label = <FormattedMessage id={"NationalResults"} />;
      }
    }

    return (
      <LinksGroup
        {...item}
        itemKey={item.key}
        mapRef={mapRef}
        markerRef={markerRef}
        popupRef={popupRef}
        canvasRef={canvasRef}
        addFilter={addFilter}
        selectFeature={selectFeature}
        unselectFeature={unselectFeature}
        zoomAndReveal={zoomAndReveal}
        hideLayer={hideLayer}
        showLayer={showLayer}
        getParallelFeatures={getParallelFeatures}
        currentLayer={currentLayer}
        goHome={goHome}
        removeCover={removeCover}
      />
    );
  });

  let homeIconSize = "1.2rem";
  let backIconSize = "1.2rem";
  //if (window.innerWidth > 3000)
  //homeIconSize = '2.4rem';

  return (
    <Navbar
      width={{ sm: 250 }}
      p="sm"
      className={classes.navbar}
      sx={{
        right: app.reverse ? "0px" : "auto",
        left: app.reverse ? "auto" : "0px"
      }}
    >
      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div
          className={"home"}
          style={{
            marginTop: "20px",
            width: "100%",
            textAlign: "center",
            backgroundColor: "#000",
            fontWeight: "600",
            padding: "5px 0px",
            fontSize: "18px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          onClick={goHome}
        >
          <IconHome size={homeIconSize} strokeWidth="2.3" /> &nbsp;
          <FormattedMessage id={"Home"} />
        </div>

        <div
          className={"back"}
          style={{
            marginTop: "10px",
            width: "100%",
            textAlign: "center",
            backgroundColor: "#000",
            fontWeight: "600",
            padding: "5px 0px",
            fontSize: "18px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          onClick={goBack}
        >
          <IconArrowBack size={backIconSize} strokeWidth="2.3" /> &nbsp;
          <FormattedMessage id={"Back"} />
        </div>
        {links && <div className={classes.linksInner}>{links}</div>}
      </Navbar.Section>

      <Navbar.Section className={classes.footer}></Navbar.Section>
    </Navbar>
  );
}
